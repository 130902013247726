
  import { mapGetters } from 'vuex'
  import SelectionCard from '~/components/external-integrations/SelectionCard.vue'

  export default {
    name: `ProviderList`,
    components: {
      SelectionCard
    },
    props: {},
    async fetch() {
      await this.$store.dispatch(`integrations/getProviders`)
    },
    computed: {
      ...mapGetters({
        providers: `integrations/providers`
      })
    }
  }
